import React, {useEffect, useState} from 'react';
import logo from './logo.svg';
import './App.css';
import Modals from './BuildingBlocks/Modals';
import Page_Home from './BuildingBlocks/Pages';
import { cloudFunctions, db } from './utils/firebase';
import { doc, getDoc } from 'firebase/firestore';
import Compenent_Signededout_Homepage from './BuildingBlocks/Signedout';
import Compenent_Navbar from './BuildingBlocks/Navbar';
import { setDoc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword, updateProfile, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";

//import ShowTask from './GetTask/GetTask';
import GetTask from './GetTask/GetTask';
import GetTask_UI from './GetTask/UI';

import Tasks from './BuildingBlocks/Tasks';
import Tasks2 from './BuildingBlocks/Tasks2';




export default function App() {

  useEffect(() => {
      document.title = "Buyer Profile AI"
  }, []);

// START Login Info


const [the_userid, setThe_userid] = useState('notset');
const [the_useremail, setThe_useremail] = useState('');
const [the_username, setThe_username] = useState('');

// Check if logged in or not
const auth = getAuth();
onAuthStateChanged(auth, (user) => {
  if (user) {
    
    // User is signed in, see docs for a list of available properties
    // https://firebase.google.com/docs/reference/js/firebase.User
    const uid = user.uid;
    var input = document.getElementById('userid');
    input.value = uid;
    setThe_userid(uid);
    //alert("Signed in");
    // grab button element
    const app_div = document.querySelector('.logged_in');
    const logged_out_div = document.querySelector('.logged_out');
    // show it
    app_div.classList.remove('hidden'); 
    logged_out_div.classList.add('hidden'); 
    user.providerData.forEach((profile) => {
      console.log("ID: " + uid);
      console.log("Sign-in provider: " + profile.providerId);
      console.log("  Provider-specific UID: " + profile.uid);
      console.log("  Name: " + profile.displayName);
      console.log("  Email: " + profile.email);
      console.log("  Photo URL: " + profile.photoURL);
      var displayname_div = document.getElementById('displayname');
      //displayname_div.innerHTML = profile.displayName;
      updateUserProfile(uid, profile.displayName);
      setThe_useremail(profile.email);
      setThe_username(profile.displayName);
    });
    // ...
  } else {
    // User is signed out
    //alert("Signed out");
    // grab button element
    const app_div = document.querySelector('.logged_in');
    const logged_out_div = document.querySelector('.logged_out');

    // show it
    app_div.classList.add('hidden'); 
    logged_out_div.classList.remove('hidden');

    // ...
  }
});

const updateUserProfile = async(uid, username) => {
  // Add a new document in collection "users"
  await setDoc(doc(db, "users", uid), {
      name: username,
  }, { merge: true });
}
// END Login Info


  const [topicInput, setTopicInput] = useState("");
  const [topicInputSubjectLine, setTopicInputSubjectLine] = useState("");
  const [idea, setIdea] = useState("");

  const [selectedPage, setSelectedPage] = useState("loggedout2");


  const testarray = [
    {id: 0, field: 'Company founder', type: 'input'},
    {id: 1, field: 'Company name', type: 'input'},
    {id: 2, field: 'Date founded', type: 'input'},
    {id: 3, field: 'Description', type: 'textarea'}
  ]
  const testarrayCount = testarray.length;
  console.log(testarrayCount);
  console.log(testarray);


  if(selectedPage == "homepage") {
  return (
    <>
      
      <input type="text" className="hidden" id="userid"/>
      <input type="text" className="hidden" id="username"/>
      <Modals />
      <Compenent_Signededout_Homepage />
      <div className="logged_in hidden">
        <Compenent_Navbar />
        
        <Page_Home />
      </div>
    </>
  );
} else if(selectedPage == "loggedout"){
    return (
      <>
      <input type="text" className="hidden" id="userid"/>
      <Modals />
      <Compenent_Signededout_Homepage />
      <div className="logged_in">
      <Compenent_Navbar />
      <GetTask taskid="ZogUuFexJZU9UQDyIdXy"/>
      </div>
      <div className="main_content hidden" onClick={() => GetTask("ZogUuFexJZU9UQDyIdXy")}>Change page</div>
      
      </>
    )
} else {
  return (
    <>
      
      <input type="text" className="hidden" id="userid"/>
      <Modals />
      <Compenent_Signededout_Homepage />
      <div className="logged_in hidden">
        
    
        <Tasks2 the_userid={the_userid} the_useremail={the_useremail} the_username={the_username} />
      </div>
    </>
  );
}

}

//export default App;