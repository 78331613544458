import React, { useState, useEffect } from 'react'
import { GetCompletion, GetImage, GetContentBlocks, selectSuggestion, createKnowledge } from '../GetCompletion/GetCompletion';
import { saveNewDocument } from '../BuildingBlocks/Generate';
import { collection, getDocs, getDoc, doc, setDoc, addDoc, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { db, cloudFunctions } from '../utils/firebase';

export default function GetTask_UI({
    the_userid, brain_id, brain_name
}) {

    const [knowledge, setKnowledge] = useState('');

    const knowledge_arr = [];

    
    const getBrains = async() => {
        const q = query(collection(db, "knowledge"), where("brain", "==", brain_id));
        const querySnapshot = await getDocs(q);

        querySnapshot.forEach((doc) => {
            knowledge_arr.push({id: doc.id, title: doc.data().title, body: doc.data().body});
            console.log(doc.data().name);
        });
        console.log("Tasks: " + knowledge_arr);


        // MAKE A FUNCTION THAT AUTO GENERATES BASED ON THE TASK ID
        const final_knowledge_arr = knowledge_arr.map((knowledge) =>
            <>
            <div key={knowledge.id} id={knowledge.id} className="col mb-2 d-flex align-items-stretch">
                <div className="card flex-fill task-card">
                <img src="https://cdn.britannica.com/67/19367-050-885866B4/Valley-Taurus-Mountains-Turkey.jpg" className="card-img-top hidden" alt="..."/>
                <div className="card-body flex-column d-flex">
                    <p className="card-title">{knowledge.title}<a href="#" className="mt-auto stretched-link no_decoration_links"></a></p>
                    <p>{knowledge.body}</p>
                </div>
                </div>
            </div>
            </>
        );
        setKnowledge(final_knowledge_arr);
    }   

    useEffect(() => {
        getBrains();
        //getCloud_selected_brand_profile();
    },[])
    //
    
    const chooseReviews = async(e) => {
        //alert("hello");
        if(e.target.value === 'Analyze reviews'){
            document.getElementById("control_length").classList.remove("hidden");
            document.getElementById("control_brand_data").classList.remove("hidden");
        } else {
            document.getElementById("control_length").classList.add("hidden");
            document.getElementById("control_brand_data").classList.add("hidden");
        }
    }
    
    return (
        <>
        <textarea rows="100" id="content_summary_input" className="hidden"></textarea>
        <input type="number" id="content_count" value="0" className="hidden"></input>
        <input id="product_content_name" className="hidden"></input>
        <input id="product_content_brand_id" className="hidden"></input>

        <div id="empty_feed_ideas" className="col mb-4 d-flex align-items-stretch">
                        <div className="card flex-fill">
                            <div className="card-body flex-column d-flex">
                            <button className="btn btn-dark hidden" data-bs-toggle="modal" data-bs-target="#shareModal">Suggestions</button>
                                <div class="form-group mb-3 hidden">
                                    <label>Pick a suggested question / task</label>
                                    <select class="form-control" id="questionType" onChange={(event) => selectSuggestion(event)}>
                                    <option>Custom</option>
                                    <option>Write a bullet point FAQ for this product</option>
                                    <option>What are the top tips people have for using the product?</option>
                                    <option>Describe what people like about the product in 50 words or less</option>
                                    <option>Describe what people don't like about the product in 50 words or less</option>
                                    <option>Write a bullet point list of the top 5 complaints people have (no duplicates)</option>
                                    <option>Design a quiz that would help people determine if this product is right for them, include an example quiz taker and what their results would be</option>
                                    <option>Write a bullet point list of email subject lines and preview text</option>
                                    </select>
                                </div>
                                <div class="form-group mb-3">
                                    <label>What do you want to do?</label>
                                    <select class="form-control" id="taskType" onChange={(e) => chooseReviews(e)}>
                                        <option>Generate new content in the same format</option>
                                        <option>Ask a question</option>
                                        <option>Rewrite this in the same style</option>
                                        <option value="Analyze reviews">Analyze reviews</option>
                                    </select>
                                </div>
                                <div class="form-group mb-3 hidden" id="control_length">
                                    <label>Do you want to talk to full length or summaries?</label>
                                    <select class="form-control" id="taskLength">
                                        <option>Full length</option>
                                        <option>Summaries</option>
                                    </select>
                                </div>
                                <div class="form-group hidden" id="control_brand_data">
                                    <label>Brand and product</label>
                                    <div class="input-group mb-3">
                                        <input class="form-control" id="ai_question_brand" placeholder="Brand" aria-label="Ask a question" aria-describedby="button-addon2" />
                                        <input class="form-control" id="ai_question_product" placeholder="Product" aria-label="Ask a question" aria-describedby="button-addon2" />
                                    </div>
                                </div>
                                <div class="form-group" id="control_brand_data">
                                    <label>Talk to the brain</label>
                                    <div class="input-group mb-3">
                                        <textarea type="text" class="form-control" id="ai_question" placeholder="Say something..." aria-label="Ask a question" aria-describedby="button-addon2"></textarea>
                                    </div>
                                    <div class="input-group">
                                        <button onClick={() => GetContentBlocks(brain_id)} class="btn btn-dark" type="button" id="button-addon2"><span class="me-2">Submit</span> <i class=" bi bi-arrow-down-circle"></i></button>
                                    </div>
                                </div>
                                <div className="progress hidden mt-3" id="progress_bar_container">
                                    <div id="ai_progress_bar" className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                                <div className="card-text text-dark mt-3 hidden" id="ai_answer"></div>
                            </div>
                        </div>
                    </div>

                    <div className="col mb-4 d-flex align-items-stretch">
                        <div className="card flex-fill">
                            <div className="card-body flex-column d-flex">
                            <label>Upload knowledge to the brain</label>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" id="new_knowledge_title" placeholder="Title of this knowledge" aria-label="Ask a question" aria-describedby="button-addon2" />
                                </div>
                                <div class="input-group mb-3">
                                    <textarea class="form-control" id="new_knowledge" placeholder="Knowledge you want this brain to know..." aria-label="Ask a question" aria-describedby="button-addon2"></textarea>
                                </div>
                                <div class="input-group mb-3">
                                <button onClick={() => createKnowledge(the_userid, brain_id)} class="btn btn-dark" type="button" id="button-addon2"><span class="me-2">Upload</span> <i class=" bi bi-cloud-arrow-up"></i></button>
                                </div>
                            </div>
                        </div>
                        
                    </div>


                    <div>
                        {knowledge}
                    </div>
        
        </>
    )
      
}