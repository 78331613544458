import React, {useEffect, useState} from 'react'
import GetTask_UI from './UI';
import { cloudFunctions } from '../utils/firebase';
import { db } from '../utils/firebase';
import { collection, getDocs, getDoc, doc, setDoc, query, where } from 'firebase/firestore';


export default function GetTask({
    the_userid, brain_id
}) {

        const [brain_name, setBrainname] = useState("");

        useEffect(() => {
            getTask();
            //getCloud_selected_brand_profile();
        },[])
        const getTask = async() => {
            
        const docRef = doc(db, "brains", brain_id);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
            setBrainname(docSnap.data().name);

        } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
        }

      }



    return (
        <>
          
        
                <div className="main_hero">
                <div>
                <br></br>
                    <h3>{brain_name}</h3>
                </div>
                </div>
                
                
                
                <GetTask_UI the_userid={the_userid} brain_id={brain_id} brain_name={brain_name}/>
          

        </>
    )

}

