import React from 'react';

export default function Compenent_Signededout_Homepage({

}) {
return (
    <>
    <div className="logged_out hidden">
      <div className="black_background">
        <div className="section_center_hero home_page_splash text-white">
          <br></br><br></br>
          <img src="images/aidesk.jpg" width="350px;"/>
          <br></br><br></br>
          <h1 className="">Buyer Profile AI</h1>
          <p>An Octane AI prototype - alpha v0.001</p>
          <button type="button" className="m-2 btn btn-outline-light btn-lg home-page-button" data-bs-toggle="modal" data-bs-target="#signupModal">Register</button> 
          <button type="button" className=" btn btn-outline-light btn-lg home-page-button" data-bs-toggle="modal" data-bs-target="#signinModal">Sign in</button>
        </div>
      </div>
    </div>  
    </>
);
}
