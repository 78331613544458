import React, {useEffect, useState} from 'react';
import { db, cloudFunctions } from '../utils/firebase';
import { ContentFilter } from '../BuildingBlocks/Generate';
import { getAuth, createUserWithEmailAndPassword, updateProfile, signInWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import { collection, getDocs, getDoc, doc, setDoc, addDoc, query, where, limit, orderBy, onSnapshot } from 'firebase/firestore';
import firebase from 'firebase/compat/app';


export async function GetCompletion2(inputs, prompt, temperature, max_tokens, the_userid, the_brandid, taskid) {
    
  document.getElementById("task-save-button").classList.remove("hidden");
  document.getElementById("task-saved-button").classList.add("hidden");

  var the_prompt = prompt;

  var getinputs = document.getElementById('task_form').getElementsByClassName('task_input');
  var answers = [];

  for (var i = 0; i < getinputs.length; i++) {
    var prompt_memory = the_prompt;
    the_prompt = prompt_memory;
    answers.push(getinputs[i].value);
    //alert("${" + inputs[i] + "}");
    prompt_memory = prompt_memory.replace("${" + inputs[i] + "}", getinputs[i].value);
    the_prompt = prompt_memory;
  }
  
  // Take the answers and replace each placeholder with the correct item from the answers array, use a for loop

  // console.log(prompt_memory);

  var userid = document.getElementById("userid").value;
  var div = document.getElementById('results');
  div.innerHTML = "";
  document.getElementById("generated_results_box").classList.add('hidden');
  document.querySelector('.loading_results').classList.remove('hidden');

  const c = cloudFunctions.httpsCallable("generate-completions");
  const r = await c({ prompt: prompt_memory, temperature: temperature, max_tokens: max_tokens, userid: userid });
  console.log(r.data);
  ContentFilter(r.data.results, "results", the_userid, the_brandid, taskid);
}
    
//

export async function GetImage() {
  alert("Starting...");
  const c = cloudFunctions.httpsCallable("generate-imagegenerate");
  const r = await c({ prompt: "ultra detailed fantasy of maasai warriorin futuristic armor, elden ring, realistic, dnd character portrait, full body, dnd, rpg, lotr game design fanart by concept art, behance hd, artstation, deviantart, global illumination radiating a glowing aura global illumination ray tracing hdr render in unreal engine 5" });
  console.log(r.data.results);
  //alert(r.data.results);
  var image = document.getElementById("theimage");
  image.src = r.data.results;
}


export async function GetCompletion(content, question, docNumber, brand_name, product_name) {

    var prompt = "These are reviews for the product \"" + product_name + "\" from \"" + brand_name + "\":\n\n${reviews}\n\n===\n\nBased on these reviews write a very detailed and lengthy answer to this question: ${question}\n\n####\n\nAnswer:";
    //var prompt = "This is feedback on the Octane AI home page:\n\n${reviews}\n\n===\n\nBased on these reviews write a very detailed and lengthy answer to this question: ${question}\n\n####\n\nAnswer:";
    
    var prompt_content = prompt.replace("${reviews}", content);
    var prompt_memory = prompt_content.replace("${question}", question);

    var userid = document.getElementById("userid").value;
    
    const c = cloudFunctions.httpsCallable("generate-completions");
    const r = await c({ prompt: prompt_memory, max_tokens: 250, userid: userid });
    console.log(r.data);
  
    document.getElementById("content_summary_input").value += "\n\n" + r.data.results;

    var the_count = document.getElementById("content_count");
    the_count.value = parseInt(the_count.value) + 1;

    var ai_progress_bar_width = (parseInt(the_count.value) + 1) * (100 / (docNumber + 2)); 
    document.getElementById('ai_progress_bar').style.width = ai_progress_bar_width + '%';

    if (the_count.value == docNumber) {
      var summaries = document.getElementById("content_summary_input");
      var summaries_value = summaries.value;
      GetSummary(summaries_value, question, brand_name, product_name);
    }

    //ContentFilter(r.data.results, "results", the_userid, the_brandid, taskid);
  
}

export async function GetSummary(content, question, brand_name, product_name) {
  
  // document.getElementById("task-save-button").classList.remove("hidden");
  // document.getElementById("task-saved-button").classList.add("hidden");

  var prompt = "This is feedback on the " + product_name + " product from " + brand_name + ":\n\n${feedback}\n\n===\n\nBased on these reviews write a very detailed and lengthy answer to this question (include all important and relevant information): ${question}\n\n####\n\nAnswer:";
  //var prompt = "This is feedback on the Octane AI home page:\n\n${feedback}\n\n===\n\nBased on these reviews write a very detailed and lengthy answer to this question (include all important and relevant information): ${question}\n\n####\n\nAnswer:";

  var prompt_content = prompt.replace("${feedback}", content);
  var prompt_memory = prompt_content.replace("${question}", question);

  var userid = document.getElementById("userid").value;
  
  // Take the answers and replace each placeholder with the correct item from the answers array, use a for loop

  const c = cloudFunctions.httpsCallable("generate-completions");
  const r = await c({ prompt: prompt_memory, max_tokens: 500, userid: userid });
  console.log("Summary: " + r.data.results);
  //alert(r.data.results.trim());
  document.getElementById("ai_progress_bar").style.width = '100%';
  document.getElementById("ai_progress_bar").classList.add("bg-success");
  document.getElementById("ai_progress_bar").classList.remove("progress-bar-animated");
  document.getElementById("ai_progress_bar").classList.remove("progress-bar-striped");
  document.getElementById("progress_bar_container").classList.add("hidden");
  var div = document.getElementById("ai_answer");

  var str_completion_check = String(r.data.results);

  var remove_new_lines_completion_check = str_completion_check.trim();   
  var final_remove_new_lines_completion_check = remove_new_lines_completion_check.split("\\n").join("<br />");
        
  div.innerHTML = final_remove_new_lines_completion_check.split("\n").join("<br />");

  saveSummary(question, r.data.results, brand_name)

  //div.innerHTML = r.data.results;
  //ContentFilter(r.data.results, "results", the_userid, the_brandid, taskid);

}

export async function saveSummary(input, output, brain_id) {

  var userid = document.getElementById("userid").value;

  const thetime = new Date().getTime();

  const savedcompletion = await addDoc(collection(db, "documents"), {
    input: input,
    output: output,
    userid: userid,
    createdAt: new Date(thetime),
    brain_id: brain_id
  });

  // const branddocumentRef = await addDoc(collection(db, "documents"), {
  //   author: the_userid,
  //   body: document_body,
  //   brand_profile: brand_profile,
  //   status: "idea",
  //   createdAt: new Date(thetime),
  //   updatedAt: new Date(thetime),
  //   task: taskid,
  // });

}

export async function GetEmbedding(input) {

  const c = cloudFunctions.httpsCallable("generate-embeddings");
  const r = await c({ input: input });
  console.log(r.data.results);
  var div = document.getElementById("ai_answer");
  div.innerHTML = r.data.results;

}

// Review tagging and filtering

export async function GetFilter(doc_id, tag_handle, filter, rating, title, body, combined) {

  // Create the prompt
  var model = "text-davinci-003";
  var prompt = "${filter} Yes or no?\n\n${review}\n\nAnswer:";
  var userid = document.getElementById("userid").value;
  
  var prompt_content = prompt.replace("${filter}", filter);
  var prompt_memory = prompt_content.replace("${review}", combined);

  const c = cloudFunctions.httpsCallable("generate-filter");
  const r = await c({ prompt: prompt_memory, max_tokens: 1, model: model,userid: userid });
  //console.log(r.data.results);
  //alert(r.data.results);
  if (r.data.results.trim() === "Yes") {
    TagReviews(tag_handle, doc_id, "yes");
  } else if (r.data.results.trim() === "No") {
    TagReviews(tag_handle, doc_id, "no");
  }

  var div = document.getElementById("ai_answer");
  div.innerHTML += "<p>--- " + r.data.results + " ---<br>" + rating + "/5 <b>" + title + "</b><br>" + body + "</p>";

}

export async function TagReviews(tag_handle, doc_id, status) {
  
  if (status === "yes") {
    //alert(doc_id);
    const docRef = db.collection('reviews').doc(doc_id);
      docRef.update({
      tags: firebase.firestore.FieldValue.arrayUnion(tag_handle)
    });
  } else {
    const docRef = db.collection('reviews').doc(doc_id);
      docRef.update({
      tags_nomatch: firebase.firestore.FieldValue.arrayUnion(tag_handle)
    });
  }
  
}

// END Review tagging and filtering


// export async function GetContentBlocks(product) {

//   const c = cloudFunctions.httpsCallable("generate-contentBlocks");
//   const r = await c({ input: "hello" });
//   const contentBlocks = r.data;
//   //GetDiplomatCompletions(contentBlocks, "Summarize this for me", "Jones Road", "Miracle Balm");
//   // console.log("Boom boom pow we be rockin them now: " + contentBlocks);
//   for (let i = 0; i < contentBlocks.length; i++) {
//     //console.log(contentBlocks[i]);
//     var div = document.getElementById("ai_answer");
//     div.innerHTML += "----------------<br></br><br></br><br></br><div>" + contentBlocks[i] + "</div><br></br><br></br>";
//   }

// }

export async function GetContentBlocksOLD_REVIEWS(brand_id, brand_profile_name) {

  document.getElementById("progress_bar_container").classList.remove("hidden");
  document.getElementById("ai_progress_bar").classList.remove("bg-success");
  document.getElementById("ai_progress_bar").classList.add("progress-bar-animated");
  document.getElementById("ai_progress_bar").classList.add("progress-bar-striped");
  document.getElementById('ai_progress_bar').style.width = 2 + '%';

  const question = document.getElementById("ai_question").value;
  const product_handle = document.getElementById("product_content_name").value;
  //const brand_id = document.getElementById("product_content_brand_id").value;
  //const product_name = "Miracle Balm";
  const product_name = product_handle;
  const brand_name = brand_profile_name;

  let reviewsArray = [];

  const q = query(collection(db, "content"), where('product_handle', '==', product_handle), where('brand', '==', brand_id));
  const querySnapshot = await getDocs(q);
  

  for (let i = 0; i < querySnapshot.size; i++) {
    let doc = querySnapshot.docs[i];
    let contentBlock = doc.data().content;
    console.log(contentBlock);
    reviewsArray.push(contentBlock);
  }

  // Getting each review and putting it into a content blocks array
  // let reviewString = '';
  // let reviewNumber = 0;
  // let total_gpt3tokens = 0;
  // let reviewsArray = [];

  // const q = query(collection(db, "reviews"), where('product_handle', '==', product_handle), where('brand', '==', brand_id));
  // const querySnapshot = await getDocs(q);

  // for (let i = 0; i < querySnapshot.size; i++) {

  //   let doc = querySnapshot.docs[i];
  //   let review = doc.data();
  //   if (review.summary !== undefined && review.summary !== null) {
  //     reviewString += review.summary;
  //     total_gpt3tokens += review.summary_tokens;
  //     reviewNumber += 1;
  //     if (total_gpt3tokens >= 3600 && total_gpt3tokens <= 3700) {
  //       reviewsArray.push(reviewString);
  //       document.getElementById("ai_answer").classList.remove("hidden");
  //       document.getElementById("ai_answer").innerHTML += "<br><br>====<br><br>" + reviewString;
  //       total_gpt3tokens = 0;
  //       reviewString = '';
  //     } else if (reviewNumber === querySnapshot.size) {
  //       // Push the remaining reviewString to the array
  //       reviewsArray.push(reviewString);
  //       document.getElementById("ai_answer").classList.remove("hidden");
  //       document.getElementById("ai_answer").innerHTML += "<br><br>==LAST==<br><br>" + reviewString;
  //     }
  //   } else {
  //     //alert("BROKEN! " + review.review_id);
  //   }
  // }



  

  // const c = cloudFunctions.httpsCallable("generate-contentBlocks");
  // const r = await c({ product_handle: product_handle, brand_id: brand_id });
  const contentBlocks = reviewsArray;
  // console.log("This is what you are looking for:" + r.data);
  let blockCount = 0;
  if (reviewsArray.length <= 9){
    blockCount = reviewsArray.length;
  } else {
    blockCount = 10;
  };
  //alert(blockCount);
  

  document.getElementById('ai_progress_bar').style.width = (100 / ((blockCount * 2) + 2)) + '%';

  let progressValue = (100 / ((blockCount * 2) + 2));
  
  let deligateCompletions = '';

  // Go get answers back for each content block up to 14 times (can only fit 14 answers in the consensuscompletion)
  // If max_tokens is reduced from 245 then the number 14 can be increased
  for (let i = 0; i < contentBlocks.length  && i < 10; i++) {

    progressValue = progressValue + (100 / ((blockCount * 2) + 2));
    document.getElementById('ai_progress_bar').style.width = progressValue + '%';

    const c2 = cloudFunctions.httpsCallable("generate-deligatecompletions");
    const r2 = await c2({ max_tokens: 245, content: contentBlocks[i], product_name: product_name, brand_name: brand_name, question: question });
    deligateCompletions += "\n\n" + r2.data.results;
    console.log("Answers: " + r2.data.results);

    progressValue = progressValue + (100 / ((blockCount * 2) + 2));
    document.getElementById('ai_progress_bar').style.width = progressValue + '%';

  }

  const c3 = cloudFunctions.httpsCallable("generate-consensuscompletions");
  const r3 = await c3({ max_tokens: 1500, content: deligateCompletions, product_name: product_name, brand_name: brand_name, question: question });
  deligateCompletions += "\n\n" + r3.data.results;
  var div = document.getElementById("ai_answer");
  div.classList.remove("hidden");

  console.log("Final answer: " + r3.data.results);

  var str_completion_check = String(r3.data.results);

  var remove_new_lines_completion_check = str_completion_check.trim();   
  var final_remove_new_lines_completion_check = remove_new_lines_completion_check.split("\\n").join("<br />");
        
  div.innerHTML = final_remove_new_lines_completion_check.split("\n").join("<br />");

  document.getElementById("ai_progress_bar").style.width = '100%';
  document.getElementById("ai_progress_bar").classList.add("bg-success");
  document.getElementById("ai_progress_bar").classList.remove("progress-bar-animated");
  document.getElementById("ai_progress_bar").classList.remove("progress-bar-striped");
  document.getElementById("progress_bar_container").classList.add("hidden");

  saveSummary(question, r3.data.results, brand_profile_name, brand_id, product_handle)

}

export async function GetContentBlocks_original(brand_id, brand_profile_name) {

  document.getElementById("progress_bar_container").classList.remove("hidden");
  document.getElementById("ai_progress_bar").classList.remove("bg-success");
  document.getElementById("ai_progress_bar").classList.add("progress-bar-animated");
  document.getElementById("ai_progress_bar").classList.add("progress-bar-striped");
  document.getElementById('ai_progress_bar').style.width = 2 + '%';

  const question = document.getElementById("ai_question").value;
  const product_handle = document.getElementById("product_content_name").value;
  //const brand_id = document.getElementById("product_content_brand_id").value;
  //const product_name = "Miracle Balm";
  const product_name = product_handle;
  const brand_name = brand_profile_name;

  const c = cloudFunctions.httpsCallable("generate-contentBlocks");
  const r = await c({ product_handle: product_handle, brand_id: brand_id });
  const contentBlocks = r.data;
  console.log("This is what you are looking for:" + r.data);
  const blockCount = contentBlocks.length;
  //alert(blockCount);

  document.getElementById('ai_progress_bar').style.width = (100 / ((blockCount * 2) + 2)) + '%';

  let progressValue = (100 / ((blockCount * 2) + 2));
  
  let deligateCompletions = '';

  // Go get answers back for each content block up to 14 times (can only fit 14 answers in the consensuscompletion)
  // If max_tokens is reduced from 245 then the number 14 can be increased
  for (let i = 0; i < contentBlocks.length  && i < 14; i++) {

    progressValue = progressValue + (100 / ((blockCount * 2) + 2));
    document.getElementById('ai_progress_bar').style.width = progressValue + '%';

    const c2 = cloudFunctions.httpsCallable("generate-deligatecompletions");
    const r2 = await c2({ max_tokens: 245, content: contentBlocks[i], product_name: product_name, brand_name: brand_name, question: question });
    deligateCompletions += "\n\n" + r2.data.results;

    progressValue = progressValue + (100 / ((blockCount * 2) + 2));
    document.getElementById('ai_progress_bar').style.width = progressValue + '%';

  }

  const c3 = cloudFunctions.httpsCallable("generate-consensuscompletions");
  const r3 = await c3({ max_tokens: 500, content: deligateCompletions, product_name: product_name, brand_name: brand_name, question: question });
  deligateCompletions += "\n\n" + r3.data.results;
  var div = document.getElementById("ai_answer");
  div.classList.remove("hidden");

  console.log(r3.data.results);

  var str_completion_check = String(r3.data.results);

  var remove_new_lines_completion_check = str_completion_check.trim();   
  var final_remove_new_lines_completion_check = remove_new_lines_completion_check.split("\\n").join("<br />");
        
  div.innerHTML = final_remove_new_lines_completion_check.split("\n").join("<br />");

  document.getElementById("ai_progress_bar").style.width = '100%';
  document.getElementById("ai_progress_bar").classList.add("bg-success");
  document.getElementById("ai_progress_bar").classList.remove("progress-bar-animated");
  document.getElementById("ai_progress_bar").classList.remove("progress-bar-striped");
  document.getElementById("progress_bar_container").classList.add("hidden");

  //saveSummary(question, r.data.results, brand_name)

}

export async function WebScrape() {
  const website = document.getElementById("ai_question").value;
  const c = cloudFunctions.httpsCallable("generate-getwebcontent");
  const r = await c({ website: website });
  console.log(r.data.results);
  var div = document.getElementById("ai_answer");
  const string = r.data.results;
  const filteredString = string.replace(/<[^>]*>/g, '');
  div.innerHTML = string;
}

export async function selectSuggestion(event){

  const selectedOption = event.target.value;
  if (selectedOption == "Custom"){
    document.getElementById("ai_question").value = '';
  } else {
    document.getElementById("ai_question").value = selectedOption;
  }
  
}



export async function SaveContentBlocks(brand_id) {

  let contentBlocks = [];
  const the_product_handle = document.getElementById("product_content_name").value;

  // Get a list of each product_handle for this brand_id and put them into an array
  const q = query(collection(db, "products"), where("brand", "==", brand_id), where("product_handle", "==", the_product_handle));
  const querySnapshot = await getDocs(q);

  querySnapshot.forEach((doc) => {
    contentBlocks.push({id: doc.id, product_handle: doc.data().product_handle, product_id: doc.data().product_id, brand: doc.data().brand});
      // if (doc.data().content !== undefined && doc.data().content !== null) {
      //   alert("Skipped: " + doc.data().product_handle);
      // } else {
      //   contentBlocks.push({id: doc.id, product_handle: doc.data().product_handle, product_id: doc.data().product_id, brand: doc.data().brand});
      // }
  });
  alert("Starting on " + contentBlocks[0].product_handle);

  // Then take the array and create content blocks of reviews, ordered by date, for each product and save the content blocks into an array
  // inside of the content collection (include brand_id and product_handle and product_id)
  for (let i = 0; i < contentBlocks.length; i++) {

    //alert(contentBlocks[i].product_handle);

    let product_handle = contentBlocks[i].product_handle;
    let product_id = contentBlocks[i].product_id;
    let reviewString = '';
    let reviewNumber = 0;
    let total_gpt3tokens = 0;
    let reviewsArray = [];
    const thetime = new Date().getTime();

    const q = query(collection(db, "reviews"), where('product_handle', '==', product_handle), where('brand', '==', brand_id), limit(5000));
    const querySnapshot = await getDocs(q);
    alert("Ok, whew! I just grabbed " + querySnapshot.size + " reviews for " + product_handle + "!");


    for (let i = 0; i < querySnapshot.size; i++) {

      let doc = querySnapshot.docs[i];
      let review = doc.data();
      if (review.summary !== undefined && review.summary !== null) {
        reviewString += review.summary;
        total_gpt3tokens += review.summary_tokens;
        reviewNumber += 1;
        //alert(review.review_id + " | " + total_gpt3tokens);
        if (total_gpt3tokens >= 3600 && total_gpt3tokens <= 3700) {
          //reviewsArray.push(reviewString);
          console.log(reviewString);
          addDoc(collection(db, 'content'), { 
            brand: brand_id,
            product_handle: product_handle,
            product_id: product_id,
            type: "reviews",
            size: "summary",
            content: reviewString,
            full_tokens: total_gpt3tokens,
            createdAt: new Date(thetime)
          });
          alert("Created a content block with " + total_gpt3tokens + " tokens and up to review #" + reviewNumber + "/" + querySnapshot.size);
          total_gpt3tokens = 0;
          reviewString = '';
        } else if (reviewNumber === querySnapshot.size) {
          //reviewsArray.push(reviewString);
          console.log(reviewString);
          addDoc(collection(db, 'content'), { 
            brand: brand_id,
            product_handle: product_handle,
            product_id: product_id,
            type: "reviews",
            size: "summary",
            content: reviewString,
            full_tokens: total_gpt3tokens,
            createdAt: new Date(thetime),
            review_number: reviewNumber
          });
          alert("DONE! Created a content block with " + total_gpt3tokens + " tokens and up to review #" + reviewNumber + "/" + querySnapshot.size);
        }
      } else {
        // A review doesn't have all the data it is supposed to have
        reviewNumber += 1;
      }
    }

  }
  alert("Ok, I actually completely done with " + the_product_handle + "!");
  // Save in the review document the id of the content block it was saved to, this way it's easy to group reviews added
  // in the future by identifying which reviews haven't been added to a block yet

}

export async function GetContentBlocks(brain_id) {

  document.getElementById("progress_bar_container").classList.remove("hidden");
  document.getElementById("ai_progress_bar").classList.remove("bg-success");
  document.getElementById("ai_progress_bar").classList.add("progress-bar-animated");
  document.getElementById("ai_progress_bar").classList.add("progress-bar-striped");
  document.getElementById('ai_progress_bar').style.width = 2 + '%';

  const type = document.getElementById("taskType").value;
  const length = document.getElementById("taskLength").value;
  //alert(type);
  const question = document.getElementById("ai_question").value;
  const product_handle = document.getElementById("product_content_name").value;
  //const brand_id = document.getElementById("product_content_brand_id").value;
  //const product_name = "Miracle Balm";
  const product_name = product_handle;
  const brand_name = document.getElementById("ai_question_brand").value;

  // const q = query(collection(db, "content"), where('brain_id', '==', brain_id));
  // const querySnapshot = await getDocs(q);
  

  // for (let i = 0; i < querySnapshot.size; i++) {
  //   let doc = querySnapshot.docs[i];
  //   let contentBlock = doc.data().content;
  //   console.log(contentBlock);
  //   reviewsArray.push(contentBlock);
  // }

  // Getting each review and putting it into a content blocks array
  let reviewString = '';
  let reviewNumber = 0;
  let total_gpt3tokens = 0;
  let reviewsArray = [];

  const q = query(collection(db, "knowledge"), where('brain', '==', brain_id));
  const querySnapshot = await getDocs(q);

  for (let i = 0; i < querySnapshot.size; i++) {

    let doc = querySnapshot.docs[i];
    let review = doc.data();

    if (length === "Full length") {
      //alert("Full length");
      if (review.body !== undefined && review.body !== null) {
        
        reviewNumber += 1;
        // If the new knowledge + existing is too big then ship off the first one and then add this content to a new one
        if (total_gpt3tokens + review.full_tokens > 3500) {
          reviewsArray.push(reviewString);
          document.getElementById("ai_answer").classList.remove("hidden");
          //document.getElementById("ai_answer").innerHTML += "<br><br>====<br><br>" + reviewString;
          total_gpt3tokens = review.full_tokens;
          reviewString = "\n\n#####\n\n" + review.title + ":\n\n" + review.body;
        } else if (total_gpt3tokens + review.full_tokens >= 2500 && total_gpt3tokens + review.full_tokens <= 3500) {
          reviewString += "\n\n#####\n\n" + review.title + ":\n\n" + review.body;
          reviewsArray.push(reviewString);
          document.getElementById("ai_answer").classList.remove("hidden");
          //document.getElementById("ai_answer").innerHTML += "<br><br>====<br><br>" + reviewString;
          total_gpt3tokens = 0;
          reviewString = '';
        } else if (reviewNumber === querySnapshot.size) {
          // Push the remaining reviewString to the array
          reviewString += "\n\n#####\n\n" + review.title + ":\n\n" + review.body;
          reviewsArray.push(reviewString);
          document.getElementById("ai_answer").classList.remove("hidden");
          //document.getElementById("ai_answer").innerHTML += "<br><br>==LAST==<br><br>" + reviewString;
        } else {
          reviewString += "\n\n#####\n\n" + review.title + ":\n\n" + review.body;
          total_gpt3tokens += review.full_tokens;
        }
      } else {
        //alert("BROKEN! " + review.review_id);
        
      }
    } else if (length === "Summaries"){
      //alert("Summaries");
      if (review.summary !== undefined && review.summary !== null) {
      
        reviewNumber += 1;
        // If the new knowledge + existing is too big then ship off the first one and then add this content to a new one
        if (total_gpt3tokens + review.summary_tokens > 3500) {
          reviewsArray.push(reviewString);
          document.getElementById("ai_answer").classList.remove("hidden");
          //document.getElementById("ai_answer").innerHTML += "<br><br>==BIGGER THAN 3500==<br><br>" + reviewString;
          total_gpt3tokens = review.summary_tokens;
          reviewString = " ###Review: " + review.summary;
        } else if (total_gpt3tokens + review.summary_tokens >= 2800 && total_gpt3tokens + review.summary_tokens <= 3500) {
          reviewString += " ###Review: " + review.summary;
          reviewsArray.push(reviewString);
          document.getElementById("ai_answer").classList.remove("hidden");
          //document.getElementById("ai_answer").innerHTML += "<br><br>==BETWEEN 3000 and 3500==<br><br>" + reviewString;
          total_gpt3tokens = 0;
          reviewString = '';
        } else if (reviewNumber === querySnapshot.size) {
          // Push the remaining reviewString to the array
          reviewString += " ###Review: " + review.summary;
          reviewsArray.push(reviewString);
          document.getElementById("ai_answer").classList.remove("hidden");
          //document.getElementById("ai_answer").innerHTML += "<br><br>==LAST==<br><br>" + reviewString;
        } else {
          reviewString += " ###Review: " + review.summary;
          total_gpt3tokens += review.summary_tokens;
          //alert("Review Title: " + review.title + ", Review summary: " + review.summary + ", Tokens: " + review.summary_tokens + ", Total tokens: " + total_gpt3tokens);
        }
      } else {
        //alert("BROKEN! " + review.review_id);
        
      }
    }
    
  }

  const contentBlocks = reviewsArray;
  let blockCount = 0;
  if (reviewsArray.length <= 9){
    blockCount = reviewsArray.length;
  } else {
    blockCount = 10;
  };
 
  

  document.getElementById('ai_progress_bar').style.width = (100 / ((blockCount * 2) + 2)) + '%';

  let progressValue = (100 / ((blockCount * 2) + 2));
  
  let deligateCompletions = '';

  // Go get answers back for each content block up to 14 times (can only fit 14 answers in the consensuscompletion)
  // If max_tokens is reduced from 245 then the number 14 can be increased
  for (let i = 0; i < contentBlocks.length  && i < 10; i++) {

    progressValue = progressValue + (100 / ((blockCount * 2) + 2));
    document.getElementById('ai_progress_bar').style.width = progressValue + '%';
    //console.log("Contents: " + contentBlocks[i]);
    const c2 = cloudFunctions.httpsCallable("generate-knowledgecompletions");
    const r2 = await c2({ max_tokens: 245, content: contentBlocks[i], question: question, type: type, brand_name: brand_name, product_name: product_name });
    deligateCompletions += "\n\n" + r2.data.results;
    //console.log("Answers: " + r2.data.results);

    progressValue = progressValue + (100 / ((blockCount * 2) + 2));
    document.getElementById('ai_progress_bar').style.width = progressValue + '%';

  }
  console.log("ALL OF IT: " + deligateCompletions);
  const c3 = cloudFunctions.httpsCallable("generate-answercompletions");
  const r3 = await c3({ max_tokens: 1500, content: deligateCompletions, question: question, type: type, brand_name: brand_name, product_name: product_name });
  deligateCompletions += "\n\n" + r3.data.results;
  var div = document.getElementById("ai_answer");
  div.classList.remove("hidden");

  console.log("Final answer: " + r3.data.results);

  var str_completion_check = String(r3.data.results);

  var remove_new_lines_completion_check = str_completion_check.trim();   
  var final_remove_new_lines_completion_check = remove_new_lines_completion_check.split("\\n").join("<br />");
        
  div.innerHTML = final_remove_new_lines_completion_check.split("\n").join("<br />");

  document.getElementById("ai_progress_bar").style.width = '100%';
  document.getElementById("ai_progress_bar").classList.add("bg-success");
  document.getElementById("ai_progress_bar").classList.remove("progress-bar-animated");
  document.getElementById("ai_progress_bar").classList.remove("progress-bar-striped");
  document.getElementById("progress_bar_container").classList.add("hidden");

  //saveSummary(question, r3.data.results, brand_profile_name, brand_id, product_handle)
  saveSummary(question, r3.data.results, brain_id);

}

export async function createKnowledge(the_userid, brain_id) {

  var new_knowledge = document.getElementById('new_knowledge').value;
  var new_knowledge_title = document.getElementById('new_knowledge_title').value;
  if (new_knowledge.length < 1) {
    alert("Woops! You didn't add anything!");
  } else {
    // Add a new document with a generated id.
    const knowledgeRef = await addDoc(collection(db, "knowledge"), {
      body: new_knowledge,
      title: new_knowledge_title,
      brain: brain_id,
      creator: the_userid
    });
    document.getElementById('new_knowledge').value = '';
    //console.log("Document written with ID: ", knowledgeRef.id);
  }
}